import ApiController from '../api.controller'
import IDBController from '../idb.controller.js'

export default {
  async loginInLocation (locationId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locationId }) })
    if(data && data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    if(data && data.location){ IDBController.storeInIDB('locations', data.location) }
    return data
  },

  async getPaginatedLocations (filter = { offset: 0, limit: 100 }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/locations/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.locations){ IDBController.storeInIDB('locations', d.locations) }
    return d
  },

  async getLocationsStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getLocationsGeometries (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations/geometries/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async deleteLocations (locations, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locations }) })
  },

  async getLocation (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.location){ IDBController.storeInIDB('locations', d.location) }
    return d
  },

  async getLocationMetas (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationBytecode (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/bytecode?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationMini (locationId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ locationId }) })
    if(d && d.location){ IDBController.storeInIDB('locations', d.location) }
    return d
  },

  async getLocationLocations (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/location/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.locations){ IDBController.storeInIDB('locations', d.locations) }
    return d
  },

  async getLocationStructure (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/structure/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getPaginatedLocationGeometries (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/geometries/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filter) })
  },

  async getLocationPeriods (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/periods?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationActions (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/actions?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationSensors (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/sensors?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationUsers (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/users?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationDevices (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/devices?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getLocationStatistics (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/statistics?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async createLocation (location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async updateLocation (location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async patchLocation (locationId, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/${locationId}?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ location }) })
  },

  async restoreLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/restore?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async cloneLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/clone?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async deleteLocation (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE' })
  },

  async setLocationMetas (metas, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ metas , location }) })
  },

  async setLocationUsers (users, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/users?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ users , location }) })
  },

  async setLocationDevices (devices, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/devices?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ devices , location }) })
  },

  async setLocationPeriods (periods, location, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/location/periods?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ periods , location }) })
  },

  // TYPES
  // GENERAL
  get JANBY_LOCATION_BRAND () { return 'janby/location/brand/' },
  get JANBY_LOCATION_DIVISION () { return 'janby/location/division/' },
  get JANBY_LOCATION_GROUP () { return 'janby/location/group/' },
  get JANBY_LOCATION_KITCHEN () { return 'janby/location/kitchen/' },
  get JANBY_LOCATION_PRODUCTION () { return 'janby/location/production/' },
  get JANBY_LOCATION_SELLING () { return  'janby/location/selling/' },
  get JANBY_LOCATION_WAREHOUSE () { return 'janby/location/warehouse/' },

  get JANBY_UBICATION_ROOM () { return 'janby/ubication/room/' },
  get JANBY_UBICATION_SHELF () { return 'janby/ubication/shelf/' },
  get JANBY_UBICATION_TABLE () { return 'janby/ubication/table/' },
  get JANBY_UBICATION_SEAT () { return 'janby/ubication/seat/' },
  get JANBY_UBICATION_POSITION () { return 'janby/ubication/position/' },

  get showDefaultLocationTypes() { 
    return [
      this.JANBY_LOCATION_BRAND,
      this.JANBY_LOCATION_DIVISION,
      this.JANBY_LOCATION_GROUP,
      this.JANBY_LOCATION_KITCHEN,
      this.JANBY_LOCATION_PRODUCTION,
      this.JANBY_LOCATION_SELLING,
      this.JANBY_LOCATION_WAREHOUSE,
    ]
  },
}
