import ApiController from '../api.controller'
import IDBController from '../idb.controller.js'

export default {
  async loginInUser (userId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ userId }) })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    if(data && data.user){ IDBController.storeInIDB('users', data.user) }
    return data
  },

  async loginAsUser (userId, filter = {}, options = {}) {
    const data = await ApiController.fetchWithJwt(`/api/v0/auth/user/as/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ userId }) })
    if(data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async getPaginatedUsers (filter = { offset: 0, limit: 100, by: 'lastLogin', sort: 'desc' }, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/users/get`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...filter }) })
    if(d && d.users){ IDBController.storeInIDB('users', d.users) }
    return d
  },

  async deleteUsers (users, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/users?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ users }) })
  },

  async getUsersLimits (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/users/limits?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getMe (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getUser (filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
    if(d && d.user){ IDBController.storeInIDB('users', d.user) }
    return d
  },

  async getUserBytecode (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/bytecode?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getUserMini (userId, filter = {}, options = {}) {
    const d = await ApiController.fetchWithJwt(`/api/v0/user/mini/get?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ userId }) })
    if(d && d.user){ IDBController.storeInIDB('users', d.user) }
    return d
  },

  async getMyLocations (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getUserLocations (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getMyMetas (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getUserMetas (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getMyPaginatedLogins (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/logins?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getPaginatedUserLogins (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/logins?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getMyPermissions (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/permissions?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getUserPermissions (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/permissions?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getPaginatedUserRequests (filter = { offset: 0, limit: 100 }, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/requests?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async getPaginatedUserHistory (filter = { offset: 0, limit: 100 }, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/history?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async updateMe (me, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ me }) })
  },

  async createUser (user, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ user }) })
  },

  async updateUser (user, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ user }) })
  },

  async patchUser (userId, user, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/${userId}?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ user }) })
  },

  async restoreUser (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/restore?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST' })
  },

  async deleteUser (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE' })
  },

  async setMyEmail (user, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/email?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ user }) })
  },

  async setUserEmail (user, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/email?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ user }) })
  },

  async setMyLocations (locations, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ locations, user }) })
  },

  async setUserLocations (locations, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/locations?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ locations, user }) })
  },

  async setMyMetas (metas, product, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ metas, product }) })
  },

  async setUserMetas (metas, product, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/metas?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ metas, product }) })
  },

  async addMyLicense (license, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/license?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ license, user }) })
  },

  async addUserLicense (license, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/license?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'PUT', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ license, user }) })
  },

  async removeMyLicense (license, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/me/license?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ license, user }) })
  },

  async removeUserLicense (license, user = null, filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/user/license?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', headers: { 'Content-type': 'Application/json' }, body: JSON.stringify({ license, user }) })
  },

  getRoleLevels () {
    return [
      { id: 0, name: 'janby/role/user' },
      { id: 1, name: 'janby/role/manager' },
      { id: 2, name: 'janby/role/executive' },
      { id: 3, name: 'janby/role/corporate' },
      { id: 4, name: 'janby/role/admin' },
      { id: 5, name: 'janby/role/owner' },
    ]
  },

  getCompanyPositions () {
    return [
      { label: 'janby/user/company/owner', options: ['janby/user/company/owner'] },
      { label: 'janby/user/company/manager', options: ['janby/user/company/manager'] },
      { label: 'janby/user/company/operations', options: ['janby/user/company/operations/manager', 'janby/user/company/operations'] },
      { label: 'janby/user/company/sales', options: ['janby/user/company/sales/manager', 'janby/user/company/sales'] },
      { label: 'janby/user/company/finances', options: ['janby/user/company/finances/manager', 'janby/user/company/finances'] },
      { label: 'janby/user/company/purchases', options: ['janby/user/company/purchases/manager', 'janby/user/company/purchases'] },
      { label: 'janby/user/company/marketing', options: ['janby/user/company/marketing/manager', 'janby/user/company/marketing'] },
      { label: 'janby/user/company/advice', options: ['janby/user/company/advice/manager', 'janby/user/company/advice'] },
      { label: 'janby/user/company/technology', options: ['janby/user/company/technology/manager', 'janby/user/company/technology'] },
      { label: 'janby/user/company/chef', options: ['janby/user/company/chef/corporate', 'janby/user/company/chef'] },
      { label: 'janby/user/company/operator', options: ['janby/user/company/operator'] },
      { label: 'janby/user/external', options: ['janby/user/external/colaborator', 'janby/user/external/contact'] }
    ]
  },

  canDelete (modelName, permissions = {}) {
    return (permissions || {})[ this.getPermissionJwtKey(modelName) ] >= 4
  },

  canCreate (modelName, permissions = {}) {
    return (permissions || {})[ this.getPermissionJwtKey(modelName) ] >= 3
  },

  canEdit (modelName, permissions = {}) {
    return (permissions || {})[ this.getPermissionJwtKey(modelName) ] >= 2
  },

  canView (modelName, permissions = {}) {
    return (permissions || {})[ this.getPermissionJwtKey(modelName) ] >= 1
  },

  getPermissionJwtKey (modelName) {
    switch (modelName) {
      case 'Me': case 'ME': return 'ME'
      case 'Organization': case 'O':  return 'O'
      case 'Location': case 'L': return 'L'
      case 'Device': case 'D': return 'D'
      case 'DevicePosition': case 'D': return 'D'
      case 'Product': case 'P': return 'P'
      case 'ProductBatching': case 'PB': return 'PB'
      case 'ProductModifier': case 'PM': return 'PM'
      case 'Program': case 'R': return 'R'
      case 'ElaborationState': case 'RS': return 'RS'
      case 'ElaborationStep': case 'E': case 'ElaborationRoute':  return 'E'
      case 'Attachment': case 'A': return 'A'
      case 'Menu': return 'M'
      case 'User': return 'U'
      case 'UserRole': return 'RO'
      case 'UserToken': return 'UT'
      case 'AuthLicense': return 'LI'
      case 'StorePurchase': return 'PU'
      case 'Container': return 'C'
      case 'ContainerShape': return 'CF'
      case 'Label': return 'LA'
      case 'LabelTemplate': return 'LT'
      case 'Event': return 'EV'
      case 'Stream': return 'ST'
      case 'Notification': return 'N'
      case 'NotificationForwarder': return 'N'
      case 'Stock': return 'CS'
      case 'Report': return 'RE'
      case 'GlobalAlert': return 'GA'
      case 'HaccpPoint': case 'HaccpPointOption': return 'HP'
      case 'Equipment': return 'PF'
      case 'Action': return 'AC'
      case 'License': return 'QL'
      case 'Sensor': return 'PS'
      case 'Software': return 'PS'
      case 'Order': return 'OR'
      case 'OrderForwarder': return 'OF'
      case 'Task': return 'TA'
      case 'Trigger': return 'TR'
      case 'Pattern': return 'PA'
      case 'Forecaster': return 'FO'
      case 'Experiment': return 'EX'
      case 'ExperimentQualityParameter': return 'QP'
      case 'ThirdPartyConnector': return 'TP'
      case 'StockCheckpoint': return 'SC'
      case 'StockCheckpointBatch': return 'CB'
      case 'Webhook': return 'WB'
      case 'Crm': return 'CRM'
      case 'CrmCompany': return 'CRM'
      case 'CrmContact': return 'CRM'
      case 'CrmTicket': return 'CRM'
      default:
        if (modelName.indexOf('is') != 0) console.log(modelName)
        return modelName
    }
  },

  getModelIcon (modelName) {

    modelName = modelName ? modelName.toLowerCase() : null

    const keysMap = {
      organization: 'business',
      company: 'business',
      user: 'people_alt',
      contact: 'people_alt',
      location: 'storefront',
      device: 'router',
      equipment: 'hardware',
      position: 'grid_4x4',
      product: 'restaurant',
      menu: 'menu_book',
      program: 'timer',
      elaborationstate: 'linear_scale',
      elaborationroute: 'linear_scale',
      action: 'pending_actions',
      productbatching: 'receipt_long',
      label: 'receipt_long',
      labelBatch: 'receipt_long',
      labeltemplate: 'print',
      container: 'pin_drop',
      experiment: 'science',
      qualityparameter: 'science',
      report: 'fact_check',
      scheduledreport: 'fact_check',
      storepurchase: 'shopping_cart',
      haccppoint: 'health_and_safety',
      license: 'extension',
      userToken: 'watch',
      webhook: 'code',
      task: 'edit_calendar',
    }

    return keysMap[modelName] ? keysMap[modelName] : 'list'
  },
}
